// material
import { styled, alpha } from "@mui/material/styles";
import { Stack, Container, Box, Typography, Button, Grid, Icon, Hidden } from "@mui/material";
import SvgIconStyle from "components/SvgIconStyle";
import MContainer from "components/MContainer";
import HexIcon from "components/HexIcon";

// ----------------------------------------------------------------------
export default function Features() {
  return (
    <MContainer id="features" sx={{ position: "relative",mt:8, pt: 4, pb: 4, backgroundColor:'#A3E7FD',maxWidth:'unset !important', textAlign:'center'}}>
      
      <Grid container sx={{maxWidth:'1440px', margin:'auto'}}>
        <Grid item sm={4} xs={6}>

            <div>
              <Box component="img" src="/images/icons/features/clock.png" sx={{ width: 1}} />
              <Typography  variant="h4"  color="primary" sx={{ mt: 3 }}>
                Quick Delivery
              </Typography>
              <Typography sx={{ mt: 1 }}>
                We make sure your products arrive quickly and provide you with tracking info.
              </Typography>
            </div>
        </Grid>

        <Grid item sm={4} xs={6}>
            <div>
              <Box component="img" src="/images/icons/features/priceTag.png" sx={{ width: 1}} />
              <Typography  variant="h4"  color="primary" sx={{ mt: 3 }}>
                Great Deals
              </Typography>
              <Typography sx={{ mt: 1 }}>
                Our relationships with vendors lets us offer better prices than our competitors.
              </Typography>
            </div>
        </Grid>

        <Grid item sm={4} xs={6}>
            <div>
              <Box component="img" src="/images/icons/features/rep.png" sx={{ width: 1}} />
              <Typography  variant="h4"  color="primary" sx={{ mt: 3 }}>
                Sales Team
              </Typography>
              <Typography sx={{ mt: 1 }}>
                Talk directly with our friendly sales representatives who can handle your shopping for you and save you time.
              </Typography>
            </div>
        </Grid>

        <Grid item sm={4} xs={6}>
            <div>
              <Box component="img" src="/images/icons/features/cc.png" sx={{ width: 1,mt:4}} />
              <Typography  variant="h4"  color="primary" sx={{ mt: 3 }}>
                Simple Reconciling
              </Typography>
              <Typography sx={{ mt: 1 }}>
                It doesn't matter how many items you order or how many days they ship on. You will only be charged one time per order.
              </Typography>
            </div>
        </Grid>

        <Grid item sm={4} xs={6}>
            <div>
              <Box component="img" src="/images/icons/features/products.png" sx={{ width: 1,mt:4}} />
              <Typography  variant="h4"  color="primary" sx={{ mt: 3 }}>
                Millions of Products
              </Typography>
              <Typography sx={{ mt: 1 }}>
                We offer over 4 Million SKUs to choose from. 
              </Typography>
            </div>
        </Grid>

        <Grid item sm={4} xs={6}>
            <div>
              <Box component="img" src="/images/icons/features/onlineShop.png" sx={{ width: 1,mt:4}} />
              <Typography  variant="h4"  color="primary" sx={{ mt: 3 }}>
                E-Procurement Store
              </Typography>
              <Typography sx={{ mt: 1 }}>
                Streamline purchasing through your E-Procurement interface to reduce transactional costs.
              </Typography>
            </div>
        </Grid>
      </Grid>

    </MContainer>
  );
}
