
import * as React from "react";

function ArrowIcon(props) {
  return (
    <svg  {...props} width="28" height="45" viewBox="0 0 28 45" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M10.4257 42.8333L26.2167 26.952C28.5944 24.5606 28.5944 20.6975 26.2167 18.3061L10.4257 2.42476C6.58465 -1.37696 0 1.32104 0 6.77834V38.4798C0 43.9984 6.58465 46.6964 10.4257 42.8333Z" fill="black"/>
	</svg>
  );
}

export default ArrowIcon;