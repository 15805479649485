
import * as React from "react";

function HexIcon(props) {
  return (
    <svg  className="headerIcon"  {...props} width="92" height="95" viewBox="0 0 92 95" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M30.1862 9.47761L36.8679 21.3246L30.1862 33.1716H17.2019L10.5675 21.3246L17.2019 9.47761H30.1862ZM34.3563 0H13.0317C12.1313 0 11.3257 0.521268 10.9466 1.23209L0.426492 19.9978L0 21.3246L0.426492 22.6515L10.9466 41.4171C11.3257 42.128 12.1313 42.6492 13.0317 42.6492H34.3563C35.2567 42.6492 36.0623 42.128 36.4414 41.4171L46.9615 22.6515L47.388 21.3246L46.9615 19.9978L36.4414 1.23209C36.0623 0.521268 35.2567 0 34.3563 0M74.0201 35.541L80.5597 47.388L74.0201 59.235H61.0358L54.4962 47.388L61.0358 35.541H74.0201ZM78.1903 26.0634H56.8656C55.9653 26.0634 55.1597 26.5847 54.7806 27.2955L44.2604 46.0612L43.8339 47.388L44.2604 48.7149L54.7806 67.4806C55.1597 68.1914 55.9653 68.7127 56.8656 68.7127H78.1903C79.0906 68.7127 79.8962 68.1914 80.2753 67.4806L90.7955 48.7149L91.222 47.388L90.7955 46.0612L80.2753 27.2955C79.8962 26.5847 79.0906 26.0634 78.1903 26.0634M30.1862 61.6045L36.8679 73.4515L30.1862 85.2985H17.2019L10.5675 73.4515L17.2019 61.6045H30.1862ZM34.3563 52.1268H13.0317C12.1313 52.1268 11.3257 52.6481 10.9466 53.3589L0.426492 72.1246L0 73.4515L0.426492 74.7783L10.9466 93.544C11.3257 94.2548 12.1313 94.7761 13.0317 94.7761H34.3563C35.2567 94.7761 36.0623 94.2548 36.4414 93.544L46.9615 74.7783L47.388 73.4515L46.9615 72.1246L36.4414 53.3589C36.0623 52.6481 35.2567 52.1268 34.3563 52.1268V52.1268Z" fill="#A3E7FD"/>
	</svg>
  );
}

export default HexIcon;