// material
import { Stack, Box, Typography, Button, Grid, Hidden } from "@mui/material";
// import trendingUpFill from "@mui/icons-material/home"
// components
import MContainer from "components/MContainer";
import WaveIcon from "components/WaveIcon";
import ArrowIcon from "components/ArrowIcon";
import BlocksIcon from "components/BlocksIcon";
import ProductDisplay from "../components/ProductDisplay";
import CoreTeam from "./Homepage/CoreTeam";
import DevTeam from "./Homepage/DevTeam";
import Roadmap from "./Homepage/Roadmap";
import ContactUs from "./Homepage/ContactUs";
import AboutUs from "./Homepage/AboutUs";
import Products from "./Homepage/Products";
import Features from "./Homepage/Features";
import { useState, useEffect } from 'react';
import { styled, alpha } from "@mui/material/styles";
import { Link as ScrollLink } from "react-scroll";
import Store from '../Store';
import MainGameElements from './Homepage/MainGameElements';
// ----------------------------------------------------------------------

const LinkStyle = styled(Typography)(({ theme }) => ({
  fontSize: '12px !important',
  cursor: 'pointer',
  fontWeight: 400,
  color: "#7C8998",
  marginRight: theme.spacing(5),
  textDecoration: "none",
  transition: theme.transitions.create("color", {
    duration: theme.transitions.duration.shortest,
  }),
  "&:hover": {
    color: "#FFC107",
  },
  [theme.breakpoints.down("lg")]: {
    marginRight: theme.spacing(4)
  }
}));

export default function Homepage() {
  
  return (
    <Box sx={{ position: "relative", width: 1 }}>
      
      <Hidden mdDown>
        <MContainer id="home" 
          sx={{ 
            position: "relative",
             pt: { lg:4, md: 4, sm: 5, xs: 5 },
             marginTop:'0rem',borderRadius:'19px',
             pb: { lg: 4, md: 4, sm: 5, xs: 5 },
             pl: { lg: 5, md:4, sm: 7, xs: 6 },
             filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
             backgroundColor:'#F4F6F5',
             backgroundImage:'url(/images/AMD.png)',
             backgroundSize:'contain',
             backgroundRepeat:'no-repeat',
             backgroundPosition:'right'
           }}
         >
          <Grid container>
            <Grid item lg={8} sm={6} xs={12} style={{padding:'20px'}}>

              <Typography variant="h1"  color="primary" style={{lineHeight: '70px'}}>
               Get Today's
               </Typography>
              <Typography variant="h1"  color="primary">
               Best Deals.
               </Typography>
                <div style={{backgroundColor:'#A3E7FD',position:'relative',left:'-16px',display:'inline-block', 'padding':'10px','borderRadius':'13px'}}>  
                  <Typography variant="h5" color="primary" sx={{ mt: 0 , mr:2, ml:2}}>
                    {/*<b>Quality</b>*/} <b>Top brands</b> at the best prices. 
                  </Typography>
                </div> 
              <Stack direction="row" spacing={3} sx={{ mt: 2.5 }}>
                <a href="https://usm.channelonline.com/msco/storesite/" target="_blank"  style={{textDecoration:"none"}}>
                  
                  <Button variant="text" color="primary" 
                    sx={{  
                      height: 62,
                      borderRadius: "10px",
                      fontSize:'26px',
                      lineHeight:'45px',
                      letterSpacing:'-5%',
                      backgroundColor: 'rgba(28, 28, 28, 0.04)',
                      '&:hover, &.Mui-focusVisible':{textDecoration:'none'}
                    }}>
                      Shop Now <ArrowIcon  style={{position:'relative',top:'2px',left:'4px',transform:'scale(0.6)'}}
                    />
                  </Button>
                </a>
              </Stack>
            </Grid>
          </Grid>
        </MContainer>
      </Hidden>
      <Hidden mdUp>
        <MContainer id="home" 
          sx={{ 
            position: "relative",
             pt: 0,
             marginTop:'0rem',borderRadius:'19px',
             pb: 0,
             pl: 0,
             pr: 0,
             filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
             backgroundColor:'#F4F6F5',
           }}
         >
         <img src="/images/amd.jpg" style={{borderRadius:'19px 19px 0px 0px', maxWidth:'100%'}}/>

              <Typography variant="h1"  color="primary" style={{fontSize:'35px',lineHeight:'45px',textAlign:'center'}}>
               Get Today's Best Deals.
               </Typography>

                <div style={{backgroundColor:'#A3E7FD',display:'table', margin:'auto','padding':'10px','borderRadius':'13px',marginTop:'20px',paddingLeft:'3px',paddingRight:'3px'}}>  
                  <Typography variant="h5" color="primary" sx={{ mt: 0 , mr:2, ml:2}}>
                    <b>Top brands</b> at the best prices. 
                  </Typography>
                </div> 

                <div style={{margin:'auto',width:'100%',textAlign:'center'}}>
                <a href="https://usm.channelonline.com/msco/storesite/" target="_blank"  style={{textDecoration:"none"}}>
                  
                  <Button variant="text" color="primary" 
                    sx={{  
                      height: 62,
                      borderRadius: "10px",
                      fontSize:'26px',
                      lineHeight:'45px',
                      backgroundColor: 'rgba(28, 28, 28, 0.04)',
                      letterSpacing:'-5%',
                      '&:hover, &.Mui-focusVisible':{textDecoration:'none'}
                    }}>
                      Shop Now <ArrowIcon  style={{position:'relative',top:'2px',left:'4px',transform:'scale(0.6)'}}
                    />
                  </Button>
                </a>
                </div>
        </MContainer>
      </Hidden>
      {/*<Hidden smUp>
        <Box component="img" src="/images/neon_skies_mobile.png" sx={{ width: 1, mt: -15 }} />
      </Hidden>
*/}
      <Store />
      <Features />
      <Products />
      <AboutUs />
      <ContactUs />
      {/*<DevTeam />*/}
    </Box>
  );
}
