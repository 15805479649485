// material
import { styled, alpha } from "@mui/material/styles";
import { Stack, Container, Box, Typography, Button, Grid, Icon, Hidden } from "@mui/material";
import SvgIconStyle from "components/SvgIconStyle";
import MContainer from "components/MContainer";
import HexIcon from "components/HexIcon";

// ----------------------------------------------------------------------
export default function Products() {
  return (
    <MContainer id="products" className="paddedSection" sx={{ position: "relative", pt: 12, pb: 3 }}>
      <Hidden mdDown>
        <Box
          component="img"
          src="/images/monitor.png"
          sx={{ position: { sm: "absolute", xs: "relative" }, width: { md: "400px", sm: 0.8, xs: 1 }, right: '100px', top: { md: 190, sm: 140, xs: 0 } }}
        />
      </Hidden>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <Stack sx={{ position: "relative", py: 2 }}>
          <div style={{position:'relative'}}>
            <HexIcon />
          </div>
            <Typography component="span" variant="h2"  color="primary"  style={{position:'relative',zIndex:2}}>
              Products Offered
            </Typography>
            <div style={{maxWidth:'650px',marginLeft:'50px',marginBottom:'60px'}}>
            <Typography sx={{ mt: 4 }}>
             <strong>We offer millions of products</strong> including PC's, PC Components(CPUs, GPUs, RAM, etc.), Servers, Storage, Legacy Equipment, Memory, Displays, Projectors, Printers, Scanners, Toner, Office Supplies, Network equipment, Cables, Batteries, Software, Software Subscriptions, GPS equipment, Cameras, and appliances. We have experience sourcing reasonably priced IT, industrial, medical, police, and military equipment. 
            </Typography>
            <Typography sx={{ mt: 4 }}>
             <strong>Everything but Guns & Ammo!</strong> 
            </Typography>
            
            </div>
          </Stack>
       </Grid>
      </Grid>

      <Hidden mdUp>
        <Box component="img" src="/images/monitor.png" sx={{ width: 1, mt: -10 }} />
      </Hidden>
    </MContainer>
  );
}
