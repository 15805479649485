// scroll bar
import 'simplebar/src/simplebar.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

// ----------------------------------------------------------------------

ReactDOM.render(
  <StrictMode>
   
      <BrowserRouter>
        <App />
      </BrowserRouter>
    
  </StrictMode>,
  document.getElementById('root')
);
