import { Icon } from "@iconify/react";
// routes

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 1,
  height: 1
};

const menuConfig = [
  // {
  //   title: "Home",
  //   path: "/#home"
  //   // icon: <Icon icon={homeFill} {...ICON_SIZE} />
  // // },
  // { title: "WHO WE SERVE", path: "/#whoWeServe" },
  { title: "PRODUCTS OFFERED", path: "/#products" },
  { title: "ABOUT US", path: "/#mission" },
  { title: "CONTACT US", path: "/#contact" },
  // { title: "THE TEAM", path: "/#team" }
];

export default menuConfig;
