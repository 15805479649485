import React from 'react';
import Products from './components/Products';
import Cart from './components/Cart';
import './styles/components/store.scss';
import ProductDisplay from './components/ProductDisplay'
import WhoWeServe from './components/WhoWeServe'

interface Props {
}
interface State {
  shopOpen: boolean,
  firstInteracted: boolean,
  isCartOpen: boolean,
  checkout: any,
  products: any,
  shop: any
}

const defaultState: State = {
  shopOpen: true,
  firstInteracted: false,
  isCartOpen: false,
  checkout: { lineItems: [] },
  products: [
    {
      title:'PublicSector',
      handle:'Federal',
      image:'/images/WhoWeServe/Federal.webp',
      description:'Whether you\'re with the DOJ, Military, FAA, FBI, NIH, HUD... Our knowledgeable staff members are here to serve you day or night, so don’t hesitate to contact us to get your buying done quicker. '
    },
    {
      title:'PublicSector',
      handle:'State and Local',
      image:'/images/WhoWeServe/StateAndLocal.webp',
      description:'We make it easy to find what you’re looking for and strive to offer the best customer service in the industry.  '
    },
    {
      title:'Health Research',
      handle:'Health Research',
      image:'/images/WhoWeServe/HealthAndResearch.webp',
      description:'We work with health research institutes at the forefront of new treatments and technologies helping source up-to-date hazard protection and lab equipment.'
    },
    {
      title:'Education',
      handle:'K-12 and Higher Ed',
      image:'/images/WhoWeServe/Education.webp',
      description:'We specialize in sourcing laptops, Arduinos, books, office supplies, sports equipment, and whatever else is needed to enable innovative and engaging learning experiences for students and teachers.'
    },
    {
      title:'Military',
      handle:'Military',
      image:'/images/WhoWeServe/Military.webp',
      description:'With our help, you will be able to focus on your mission by saving time on finding supplies. We are your one-stop-shop supplying everything besides guns & ammo.'
    },
    {
      title:'SystemsIntegrators',
      handle:'Systems Integrators',
      image:'/images/WhoWeServe/SystemsIntegrators.webp',
      description:'We offer expertise in installation, setup, and maintenance for all your IT needs such as wifi networks, security cameras, highspeed internet, and server farms. Legacy parts are no problem for us either.'
    },
    {
      title:'SystemsIntegrators',
      handle:'Systems Integrators',
      image:'/images/WhoWeServe/SystemsIntegrators.webp',
      description:'Utilize our expertise in wifi networks, security cameras, highspeed internet, and server farms to find and even install whatever IT products you need. Legacy equipment is no problem for us either.'
    }
  ],
  shop: {}
};

interface SearchProps {
  client: any
}
export default class Store extends React.Component<SearchProps, State> {
  
  constructor(props: SearchProps) {
    super(props);

    this.state = defaultState;
  }

  render(){
  	let modalContainerClass = (this.state.shopOpen ? 'one' : 'one out');
  	if(!this.state.firstInteracted)
  		modalContainerClass = 'one'
    
    return(
    	<>
	    	
	      	<div id="modal-container" className={modalContainerClass}>
			      <div className={'modal-background'}>
			    <div className={"modal"}>
				{/*Shop*/}
			      <div className="App Store">
              <WhoWeServe  
                products={this.state.products}
              />
			      </div>
			    </div>
			  </div>
			</div>

		</>
    )
  }
}

