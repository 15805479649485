// material
import { styled, alpha } from "@mui/material/styles";
import { Stack, Container, Box, Typography, Button, Grid, Icon, Hidden } from "@mui/material";
import SvgIconStyle from "components/SvgIconStyle";
import MContainer from "components/MContainer";
import HexIcon from "components/HexIcon";
import { Tweet } from 'react-twitter-widgets'


// ----------------------------------------------------------------------
export default function AboutUs() {
  return (
    <MContainer id="mission" className="paddedSection" sx={{ position: "relative", pt: 12, pb: 3 }}>
      {/*<Hidden smDown>
        <Box
          component="img"
          src="/images/gary.webp"
          sx={{ position: { sm: "absolute", xs: "relative" }, width: { md: "900px", sm: 0.8, xs: 1 }, right: '100px', top: { md: 190, sm: 140, xs: 0 } }}
        />
      </Hidden>*/}
      <Grid container>
      <Hidden smUp>
        <Grid item sm={12} xs={12}>
          <Stack sx={{ position: "relative", py: 2 }}>
          <div style={{position:'relative'}}>
            <HexIcon />
          </div>
            <Typography component="span" variant="h2"  color="primary"  style={{position:'relative',zIndex:2}}>
              About Us
            </Typography>
            <div style={{maxWidth:'650px',marginLeft:'50px',marginBottom:'60px'}}>
            <Typography sx={{ mt: 4 }}>
             <strong>Miners Supply Co.</strong> is a small business proud to be providing top quality customer service that makes you wonder how the giant corporations who couldn't care less are still in business. A majority of our staff are those with disabilities who couldn't be more motivated, amazing people. 
            </Typography>
            <Typography sx={{ mt: 4 }}>
             A majority of our staff are those with disabilities who couldn't be more motivated, amazing people. 
            </Typography>
            
            </div>
            
            <div style={{maxWidth:'650px',marginLeft:'50px',marginBottom:'60px'}}>
            
            <Typography sx={{ mt: 4 }}>
             <strong>Miners Supply Co.</strong> is a small business proud to be providing top quality customer service that makes you wonder how giant corporations like CDW, Office Depot, and Grainger are still in business. A majority of our staff are those with disabilities who couldn't be more motivated, amazing people. 
            </Typography>
            <Typography sx={{ mt: 4 }}>
             A majority of our staff are those with disabilities who couldn't be more motivated, amazing people. 
            </Typography>
             
            </div>
          </Stack>
        </Grid>
        
        <Grid container>
          <Grid item sm={1} xs={1}></Grid>
          <Grid item sm={10} xs={10} >
              <Tweet tweetId='1628899013000065024'/>
          </Grid>
          <Grid item sm={1} xs={1}></Grid>
        </Grid>
      </Hidden>
      
      <Hidden smDown>
        
      <Grid item sm={5} xs={12} >
              <Tweet tweetId='1628899013000065024' 
                options={{
                  height: '350'
                }}
              />
        </Grid>
        <Grid item sm={1.5} xs={12} >
        </Grid>
        <Grid item sm={5.5} xs={12}>
          <Stack sx={{ position: "relative", py: 2 ,top:'28%'}}>
          <div style={{position:'relative'}}>
            <HexIcon />
          </div>
            <Typography component="span" variant="h2"  color="primary"  style={{position:'relative',zIndex:2}}>
              About Us
            </Typography>
            <div style={{maxWidth:'650px',marginLeft:'50px',marginBottom:'60px'}}>
            <Typography sx={{ mt: 4 }}>
             <strong>Miners Supply Co.</strong> is a small business proud to be providing top quality customer service that makes you wonder how the giant corporations who couldn't care less are still in business. A majority of our staff are those with disabilities who couldn't be more motivated, amazing people. 
            </Typography>
            <Typography sx={{ mt: 4 }}>
             A majority of our staff are those with disabilities who couldn't be more motivated, amazing people. 
            </Typography>
            </div>
          </Stack>
        </Grid>
      </Hidden>
      </Grid>
{/*
      <Hidden smUp>
        <Box component="img" src="/images/gary.webp" sx={{ width: 1, mt: -10 }} />
      </Hidden>*/}
    </MContainer>
  );
}
