// material
import { styled, alpha } from "@mui/material/styles";
import { Stack, Container, Box, Typography, Button, Grid, Icon, Hidden } from "@mui/material";
import SvgIconStyle from "components/SvgIconStyle";
import MContainer from "components/MContainer";
import CirclesIcon from "components/CirclesIcon";

// ----------------------------------------------------------------------
export default function ContactUs() {
  return (
    <MContainer id="contact" className="paddedSection" sx={{ position: "relative", pt: 3 }}>
      {/*<Hidden smDown>
        <Box
          component="img"
          src="/images/contactImage.jpg"
          sx={{ position: { sm: "absolute", xs: "relative" }, width: { md: "initial", sm: 0.8, xs: 1 }, right: 0, top: { md: 100, sm: 140, xs: 0 } }}
        />
      </Hidden>*/}

               <Typography variant="h2">
              <div style={{position:'relative'}}>
                <CirclesIcon style={{position:'absolute', left:-55, top:-25, zIndex:1,transform:'rotate(12deg)  scale(0.8)'}}/>
              </div>
              <Typography component="span" variant="h2" color="primary" style={{display:'block', position:'relative',zIndex:4}}>
                Contact{" "}Us
              </Typography>
              
            </Typography>
            <br />
            <div style={{marginBottom:'140px'}}>
            <Grid container alignItems="center" rowSpacing={5} style={{alignItems:'flex-start'}}>
            <Grid item sm={4} xs={12}>
              <div style={{padding:'15px',textAlign:'center'}}>
               <Box
            component="img"
            src="/images/Phone.png"
            sx={{ width:'60px',display:'inline-block' , verticalAlign: 'middle'}}
          /> 
          <br />
              <Typography sx={{ mt: 4,display:'inline-block', width:'calc(100% - 70px)', verticalAlign: 'middle',marginTop:'10px',marginLeft:'10px' }}>
              Call us today! Our team is happy to help and is available during normal business hours.<br /><a href="tel:+15412036826" target="_blank">541-203-6826</a>
              </Typography>
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div style={{padding:'15px',textAlign:'center'}}>
               <Box
            component="img"
            src="/images/Email.png"
            sx={{ width:'60px',display:'inline-block', verticalAlign: 'middle' }}
          /> 
          <br />
              <Typography sx={{ mt: 3,display:'inline-block', width:'calc(100% - 70px)', verticalAlign: 'middle',marginTop:'10px',marginLeft:'10px' }}>
                Looking for an out-of-stock item or need to quote some prices? Send us an e-mail and we will do the work for you! <br /> <a href="mailto:contact@minerssupplyco.com" target="_blank">contact@minerssupplyco.com</a>
              </Typography>
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div style={{padding:'15px',textAlign:'center'}}>
               <Box
            component="img"
            src="/images/Address.png"
            sx={{ width:'60px',display:'inline-block', verticalAlign: 'middle' }}
          /> 
          <br />
              <Typography sx={{ mt: 3,display:'inline-block', width:'calc(100% - 70px)', verticalAlign: 'middle',marginTop:'10px',marginLeft:'10px' }}>Our Main Office is located at<br /> 8 The GRN, STE 14017, Dover, Delaware 19901-3618</Typography>
          
              </div>
            </Grid>
            </Grid>
         </div>

      {/*<Hidden smUp>
        <Box component="img" src="/images/land-ownership.png" sx={{ width: 1, mt: -10 }} />
      </Hidden>*/}
    </MContainer>
  );
}
