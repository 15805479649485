// material
import { useRef, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { Stack, Container, Box, Typography, Button, Grid, Icon, Hidden } from "@mui/material";
import SvgIconStyle from "components/SvgIconStyle";
import MContainer from "components/MContainer";
import Slider from "react-slick";
import WaveIcon from "components/WaveIcon";
import BlocksIcon from "components/BlocksIcon";
import ProductBuyButton from "components/ProductBuyButton";
import '../styles/components/customSlider.scss';


const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 1424,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 0
        // infinite: true
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 0
        // infinite: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};


// ----------------------------------------------------------------------

export default function WhoWeServe(props) {
  const Items = props.products;
  const slideRef = useRef();
  const [active, setActive] = useState(0);
  const [currentImage, setImage] = useState('/images/WhoWeServe/Enterprise.jpg');
  const [productt, setProduct] = useState(2);
  const goToSlide = (slide) => slideRef.current.slickGoTo(slide);

    return (
    <MContainer id="whoWeServe" className="paddedSection" sx={{ position: "relative", pt: 14 }}>
      <Typography variant="h2" align="left" sx={{}}  color="primary">
        
        <div style={{position:'relative'}}>
            <BlocksIcon style={{position:'absolute', left:-65, top:-45, zIndex:1,transform:'  scale(0.8)'}}/>
          </div>
        <Typography component="span" variant="h2" color="primary" style={{position:'relative',zIndex:2}}>
          Who We Serve
        </Typography>
      </Typography>
      <Slider {...sliderSettings} ref={slideRef}>
        {Items.map((product, index) => (
          <>
            <Grid container alignItems="center" rowSpacing={5} style={{alignItems:'flex-start'}}>
            
                <Stack direction={{ md: "row-reverse", xs: "column" }} sx={{ position: "relative", height: 1 }} alignItems="center" justifyContent="space-between">
               {/*   <Box component="img" src={product.image} sx={{ position: "absolute", width: { md: "initial", sm: "120%", xs: 1 }, top: 0, left: 0 }} />*/}
                  <Box component="img" sx={{ width: '100%', position: "relative",padding:'0px', margin:'0px', backgroundImage:'url('+product.image+')' }} /> 
                </Stack>
             
                <Stack sx={{ position: "relative", padding:'20px',zIndex: active === index ? 1600 : 2 }}>
                  <Typography variant="h2"  color="primary">{product.handle}</Typography>
                  <Typography sx={{ mt: 2 }}>{product.description}</Typography>
                </Stack>
             
            </Grid>
          </>
        ))}
      </Slider>
    </MContainer>
  );
}
