import * as React from "react";

function BlocksIcon(props) {
  return (
    <svg {...props} width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.3057 14.1899V28.3057H14.19V14.1899H28.3057ZM7.13211 7.13208V35.3635H35.3635V7.13208H7.13211Z" fill="#A3E7FD"/>
      <path d="M63.595 24.7769V38.8926H49.4793V24.7769H63.595ZM42.4214 17.719V45.9504H70.6528V17.719H42.4214Z" fill="#A3E7FD"/>
      <path d="M28.3057 56.5371V70.6528H14.19V56.5371H28.3057ZM7.13211 49.4792V77.7107H35.3635V49.4792H7.13211Z" fill="#A3E7FD"/>
      <path d="M77.7107 35.3635V56.5371H56.5371V77.7107H35.3635V105.942H105.942V35.3635H77.7107ZM63.595 63.595H77.7107V77.7107H63.595V63.595ZM56.5371 98.8843H42.4214V84.7685H56.5371V98.8843ZM77.7107 98.8843H63.595V84.7685H77.7107V98.8843ZM98.8843 98.8843H84.7686V84.7685H98.8843V98.8843ZM98.8843 77.7107H84.7686V63.595H98.8843V77.7107ZM84.7686 56.5371V42.4214H98.8843V56.5371H84.7686Z" fill="#A3E7FD"/>
    </svg>
  );
}

export default BlocksIcon;